import React from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom"
import './App.css'
import Footer from './Components/Footer';
import HomePage from './Pages/HomePage';
import AuthPage from './Pages/AuthPage';
import ProjectPage from './Pages/ProjectPage';
import ProjectData from './Data/Data';

function App() {

  const [projectsData, setProjectsData] = React.useState(null as ProjectData | null)
  const [aboutData, setAboutData] = React.useState(null)

  React.useEffect(() => {
    fetch('/data/projects.json')
      .then(response => response.json())
      .then(json => {
        console.log('json data loaded', json)
        setProjectsData(new ProjectData(json))
      })

    fetch('/data/about.json')
      .then(response => response.json())
      .then(json => {
        console.log('json data loaded', json)
        setAboutData(json)
      })
  }, [])

  return projectsData && aboutData && (
    <Router>
      <div className="AppContainer">
        <Switch>
          <Route exact path="/">
            <HomePage projectData={projectsData!} />
          </Route>
          <Route path="/auth/:id">
            <AuthPage projectData={projectsData} />
          </Route>
          <Route path="/projects/:id">
            <ProjectPage projectData={projectsData} />
          </Route>
          <Route path="/about">
            <ProjectPage aboutData={aboutData} id="about" />
          </Route>
        </Switch>

        <Footer/>
      </div>
    </Router>
  )
}

export default App;
