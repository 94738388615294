import React from "react"
import { Link } from "react-router-dom"
import './AboutIcon.css'

import AboutIconImage from '../Images/About.svg';

function AboutIcon() {
  return <Link to="/about" className="AboutIcon"><img src={AboutIconImage} alt="about"></img></Link>
}

export default AboutIcon
