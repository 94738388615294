import React, { ReactFragment } from 'react'
import "./ProjectPage.css"
import { useParams, Redirect } from 'react-router-dom'

import ProjectData from '../Data/Data'
import { Link } from 'react-router-dom'
import AboutIcon from '../Components/AboutIcon'

type Props = {
  id?: string
  projectData?: ProjectData
  aboutData?: any
}

function ProjectPage(props: Props) {
  const id = useParams<any>().id || props.id
  const projectData = props.projectData
  const aboutData = props.aboutData

  const project = aboutData || (projectData && projectData.projectByAltId(id!))

  if (!project) {
    return <Redirect to="/"></Redirect>
  }

  const parts: ReactFragment[] = []

  project.content.forEach((part: any, index: number) => {
    if (part.text) {
      parts.push(<div key={index} className="ProjectPage__text">{part.text}</div>)
    } else if (part.image) {
      parts.push(<img key={index} className="ProjectPage__image" src={`/images/${part.image}`} alt="thing"></img>)
    } else if (part.video) {
      const thumbnail = part.video.replace('.mp4', '_thumbnail.jpg')
      parts.push(<video
        key={index}
        controls
        preload="auto"
        className="ProjectPage__video"
        poster={`/images/${thumbnail}`}
        src={`/images/${part.video}`}></video>)
    } else if (part.title) {
      parts.push(<div key={index} className="ProjectPage__title">{part.title}</div>)
    } else if (part.file) {
      parts.push(<a key={index} href={`/${part.file.path}`} download className="ProjectPage__text ProjectPage__text--download">{part.file.text}</a>)
    } else if (part.social) {
      parts.push(<div key={index} className="ProjectPage__text">
        <a href="https://pongko.tumblr.com/" className="ProjectPage__socialLink">Tumblr</a>
        {' & '}
        <a href="https://www.instagram.com/pong_ko/" className="ProjectPage__socialLink">Instagram</a>
      </div>)
    }
  })

  return <div className="ProjectPage">
    {id !== 'about' && <AboutIcon></AboutIcon>}
    {parts}
    <Link to="/" className="ProjectPage__back">Back</Link>
  </div>
}

export default ProjectPage
