import React, { useState } from 'react'
import "./AuthPage.css"

import { useParams, Redirect } from 'react-router-dom'
import AboutIcon from '../Components/AboutIcon'

type Props = {
  projectData?: any
}

function AuthPage(props: Props) {

  const { id } = useParams()
  const { projectData } = props
  const project = projectData.projectById(id!)
  const [redirect, setRedirect] = useState('')
  const [pw, setPw] = useState('')
  const [error, setError] = useState('')

  if (!project) {
    return <Redirect to="/"></Redirect>
  }

  const onClick = () => {
    console.log("Redirect to project", project.altid)
    if (pw.toLowerCase() === 'pongko') {
      setRedirect(`/projects/${project.altid}`)
    } else if (!pw) {
      setError('Password required')
      setPw('')
    } else {
      setError('Wrong password')
      setPw('')
    }
  }

  if (redirect) {
    return <Redirect to={redirect}></Redirect>
  }

  return <div className="AuthPage">
    <AboutIcon></AboutIcon>

    <form>
      <input className="AuthPage__input" autoFocus type="text" placeholder={error} value={pw} autoComplete="off" onChange={e => setPw(e.target.value)}></input>
      <button className="AuthPage__button" onClick={onClick}>Enter</button>
    </form>
  </div>
}

export default AuthPage
