import React, { ReactFragment } from 'react'
import "./HomePage.css"
import "../Components/Header.css"
import "../Components/ProjectThumbnails.css"

import ProjectData from "../Data/Data"
import { Link } from 'react-router-dom'
import AboutIcon from '../Components/AboutIcon'

type Props = {
  projectData: ProjectData
}

function HomePage(props: Props) {
  const projectContent: ReactFragment[] = []
  const { projectData } = props

  const resetScroll = () => {
    window.scrollTo(0,0)
  }

  for (let index = 0; index < projectData.projectCount(); ++index) {
    const project = projectData.projectByIndex(index)
    projectContent.push(<Link key={index} onClick={resetScroll} to={`/${project.password ? "auth" : "projects"}/${project.id}`} className="ProjectThumbnails__thumbnail" style={{
      backgroundImage: `url(/images/${project.thumbnail})`
    }}>
    </Link>)
  }

  return <div className="HomePage">
    <AboutIcon></AboutIcon>
    <div className="Header">
      Hello, it's Pong.
      I'm a designer based in the
      rainy city of Seattle.
    </div>
    <div className="ProjectThumbnails">
      {projectContent}
    </div>
  </div>
}

export default HomePage