
let projects: any[] = []
const projectsById: Record<string, any> = {}
const projectsByAltId: Record<string, any> = {}

class ProjectData {
    constructor(projectList: any[]) {
        projects = projectList
        for (const project of projects) {
            if (project.password) {
                project.altid = Math.abs(Math.random() * 10000000 * (Date.now() ^ 56298534712)).toString(16)
            }
            projectsById[project.id] = project
            projectsByAltId[project.altid || project.id] = project
        }
    }

    projectCount() {
        return projects.length
    }

    projectByIndex(i: number) {
        return projects[i]
    }

    projectById(id: string) {
        return projectsById[id]
    }

    projectByAltId(id: string) {
        return projectsByAltId[id]
    }
}

export default ProjectData
